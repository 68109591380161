<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    append-to-body
    @close="onDialogClose">
    <el-form label-width="100px" :model="elevator">
      <div style="width: 80%">
        <el-form-item :label="$t('elevator.addNumber')">
          <el-input v-model="elevator.copyNum" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onDialogClose">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" @click="handleSubmit">{{$t("common.save")}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        elevator: {
          id: 0,
          copyNum: 0,
        },
      };
    },
    computed: {
      title() {
        return (this.elevator.id === 0 ? this.$l("elevator.batchAdding","批量新增电梯") : this.$l("elevator.batchCopy","批量复制当前电梯"));
      },
    },
    methods: {
      open(id) {
        this.elevator.copyNum = 1;
        this.elevator.id = id;
        this.dialogVisible = true;
      },
      onDialogClose() {
        this.elevator.copyNum = 0;
        this.dialogVisible = false;
      },
      handleSubmit() {
        this.$emit("submitCopy", this.elevator.copyNum);
        this.dialogVisible = false;
        this.elevator.copyNum = 0;
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
